// 行业资金流、概念资金流图表页面
<template>
  <div>
    <Plate />
    <Concept />
  </div>
</template>

<script>
import Plate from '@/components/market/plate.vue';
import Concept from '@/components/market/concept.vue';

export default {
  components: {
    Plate,
    Concept
  }
}
</script>
