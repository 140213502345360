<template>
  <div class="cono">
    <div class="title">概念资金流</div>
    <div class="types">
      <el-radio-group v-model="activeName" size="mini">
        <el-radio-button label="今日排行"></el-radio-button>
        <el-radio-button label="5日排行"></el-radio-button>
        <el-radio-button label="10日排行"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="content_box">
      <div class="echarts" ref="mainCharts"></div>
      <div v-if="!tableData.length" class="hintDiv">
        <i class="el-icon-top"></i>
        点击上方图表内的柱状体可展示该行业的个股数据
      </div>
      <div v-if="tableData.length" class="tableBox">
        <div class="titleDiv">{{ optionItem.f14 }}板块资金流</div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" align="center" width="50">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="f12" label="代码" align="center" width="70"></el-table-column>
          <el-table-column prop="f14" label="名称" align="center" width="80"></el-table-column>
          <el-table-column prop="f2" label="最新价" align="center">
            <template slot-scope="scope">{{ scope.row.f2 | CYB }}</template>
          </el-table-column>
          <el-table-column prop="f3" label="今日涨跌幅" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.f3 >= 0 ? 'red' : 'green'">{{ scope.row.f3 | CYB }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="今日主力净流入" align="center">
            <el-table-column prop="f62" label="净额" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.f62 >= 0 ? 'red' : 'green'">{{ scope.row.f62 | NETTO }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="f184" label="净占比" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.f184 >= 0 ? 'red' : 'green'">{{ scope.row.f184 | CYB }}%</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "marketBar",
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartEL: null,
      myChart: null,
      option: {},
      loading: false,
      activeName: "今日排行",
      fieldsMap: {
        今日排行: {
          fid: "f62",
        },
        "5日排行": {
          fid: "f164",
        },
        "10日排行": {
          fid: "f174",
        },
      },
      // 用户token
      token: null,
      // 图表数据初始列表
      optionData: [],
      // 个股资金流对应板块数据
      optionItem: {},
      // 个股资金流数据
      tableData: [],
    };
  },

  watch: {
    activeName(val) {
      this.getData(val);
    },
  },
  computed: {
    defaultColor() {
      return this.darkMode ? "rgba(255,255,255,0.6)" : "#ccc";
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.myChart.clear();
  },
  methods: {
    init() {
      this.chartEL = this.$refs.mainCharts;
      this.myChart = echarts.init(this.chartEL);
      this.option = {
        tooltip: {
          trigger: "axis",
          formatter: (p) => {
            return `${p[0].name}<br />${(p[0].value / 100000000).toFixed(
              2
            )}亿元`;
          },
        },
        grid: {
          top: 35,
          bottom: 110,
          left: 45,
          right: 5,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            formatter: (value) => {
              return value.split("").join("\n");
            },
          },
        },
        yAxis: {
          type: "value",
          name: "单位：亿元",
          scale: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: (val) => {
              return (val / 100000000).toFixed(2);
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              color: "#efefef",
            },
          },
          data: [],
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: 30,
          },
          {
            type: "inside",
            xAxisIndex: [0],
            start: 1,
            end: 30,
          },
        ],
        series: [
          {
            type: "bar",
            data: [],
          },
        ],
      };
      // 绑定点击事件
      this.myChart.getZr().on('click', params => {
        let pointInPixel = [params.offsetX, params.offsetY];
        if (this.myChart.containPixel('grid', pointInPixel)) {
          let xIndex = this.myChart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0];
          const { f12 } = this.optionData[xIndex];
          this.optionItem = this.optionData[xIndex];
          // 判断是否存在token，优先从本地获取，本地没有就从url上取
          const { token } = this.$route.query;
          if (localStorage.token) {
            this.token = localStorage.token;
          } else if (token) {
            this.token = token;
          } else {
            this.tableData = [];
            return this.$message.error('请先登录后查看');
          }
          this.getStocksToPlate(f12);
        }
      })
      this.getData();
    },
    // 根据行业板块获取股票数据
    getStocksToPlate(plate) {
      this.$axios.get(`https://www.hqzs.top/api/stock_plateToList?plate=${plate}`, {
        headers: {
          token: this.token
        }
      }).then((res) => {
        if (res.data.status === "000") {
          const { data } = res.data.data;
          this.tableData = [];
          // 将返回的数据对象转换成数组
          for (let item in data) {
            this.tableData.push(data[item]);
          }
        }
      })
    },
    // 获取图表数据
    getData(activeName) {
      this.loading = true;
      const { fid } = this.fieldsMap[activeName || this.activeName];
      let url = `https://push2.eastmoney.com/api/qt/clist/get?pn=1&pz=500&po=1&np=1&fields=f12,f13,f14,f62,${fid}&fid=${fid}&fs=m:90+t:3&_=${new Date().getTime()}`;
      this.$axios.get(url).then((res) => {
        this.loading = false;
        let dataList = res.data.data.diff;
        this.optionData = dataList;
        let xdata = [];
        let sdata = [];
        if (dataList) {
          dataList.forEach((el) => {
            xdata.push(el.f14);
            sdata.push(el[fid]);
          });

          this.option.xAxis.data = xdata;

          this.option.series = [
            {
              type: "bar",
              data: sdata,
              itemStyle: {
                normal: {
                  color: function (data) {
                    return data.value >= 0 ? "#f56c6c" : "#4eb61b";
                  },
                },
              },
            },
          ];
          this.myChart.setOption(this.option);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.con {
  width: 100%;
  height: 100%;
}
.title {
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #efefef;
  background-color: #efefef;
}
.types {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.content_box {
  padding: 0 10px 16px 10px;
  box-sizing: border-box;
  .echarts {
    width: 100%;
    height: 300px;
  }
  .hintDiv {
    padding: 10px 0;
    text-align: center;
    font-size: 12px;
    color: #666666;
  }
  .tableBox {
    margin-top: 20px;
    border: 1px solid #E4E7ED;
  }
  .titleDiv {
    font-size: 14px;
    padding: 10px 16px;
    font-weight: bold;
  }
  .red {
    color: #f00;
  }
  .green {
    color: #090;
  }
}
</style>
